import React, { useContext, useEffect, useRef } from "react";

import RtukGeneralIcon from "../../assets/icons/svg/RtukGeneralIcon";
import localizationStrings from "../../statics/localization";
import RtukSevenAgeIcon from "../../assets/icons/svg/RtukSevenAgeIcon";
import RtukThirteenAgeIcon from "../../assets/icons/svg/RtukThirteenAgeIcon";
import RtukAgeIcon from "../../assets/icons/svg/RtukAgeIcon";
import Namespace from "../../assets/icons/svg/Namespace";
import { SessionContext } from "../../context/SessionContext";
import LiveIcon from "../../assets/icons/svg/LiveIcon";
// import Notification from "../notification";
import { fancyHourFormatter } from "../../utils/fancyHourFormatter";
import { getProgramPlayUrl } from "../../api/ltv/ltv";

const VerticalProgramListItem = ({ program, onSelect }) => {
  const { setSelectedCutvProgram, selectedCutvProgram, setPlayUrl } =
    useContext(SessionContext);
  const programRef = useRef(null);

  useEffect(() => {
    if (selectedCutvProgram?.epgProgramId === program?.epgProgramId) {
      document
        .getElementById("program-list-container")
        .scrollTo(
          0,
          programRef.current.offsetTop - programRef.current.offsetHeight - 120
        );
    }
  }, [selectedCutvProgram]);

  const getProgramVideoUrl = async (epgId) => {
    const data = await getProgramPlayUrl(epgId);
    let programUrl = data.data;

    setPlayUrl(programUrl);
  };

  const hoursData = fancyHourFormatter(program?.startTime, program?.endTime);

  return (
    <div
      ref={programRef}
      className={`program-row ${program?.watchable ? "" : "previousprog"}`}
      onClick={(e) => {
        if (program?.watchable && !program.current) {
          setSelectedCutvProgram(program);
          getProgramVideoUrl(program?.epgProgramId);
          window.scrollTo({ top: 0, behavior: "smooth" });
        } else {
          onSelect(program);
        }
      }}
    >
      <div className="box info">
        <div
          className="title"
          title={program?.watchable ? localizationStrings.watch : null}
          style={{
            cursor: program?.watchable ? "pointer" : "not-allowed"
          }}
        >
          {program?.title}
        </div>
        <div className="genre-passingtime">
          <div className="genre">{program?.category} </div>
          <span style={{ margin: "0 5px" }}>-</span>
          <div className="date">{hoursData?.startTimeString}</div>
          <span style={{ margin: "0 5px" }}>→</span>
          <div className="date">{hoursData?.endTimeString}</div>
          <div className="passing-time"></div>
        </div>
      </div>
      <div className="image-icon-group">
        <div
          className="box progress-bar "
          style={{
            opacity: program?.current ? "1" : "0"
          }}
        >
          <div className="current-program-play-icon">{<LiveIcon />}</div>
          <span className="current-program-play-text">
            {localizationStrings.live}
          </span>
        </div>
        <div
          className="box-namespace"
          onClick={(e) => {
            e.stopPropagation();
            onSelect(program);
          }}
        >
          {<Namespace />}
        </div>
        <div className="rating">
          {program?.rating?.age === "G" ? (
            <RtukGeneralIcon />
          ) : program?.rating?.age === "7+" ? (
            <RtukSevenAgeIcon />
          ) : program?.rating?.age === "13+" ? (
            <RtukThirteenAgeIcon />
          ) : program?.rating?.age === "18+" ? (
            <RtukAgeIcon />
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default VerticalProgramListItem;
