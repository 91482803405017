import React, { useContext, useRef } from "react";
import { useHistory } from "react-router-dom";
import WatchTvIcon from "../../assets/icons/svg/WatchTvIcon";
import { SessionContext } from "../../context/SessionContext";
import localizationStrings from "../../statics/localization";
import playIcon from "../../assets/images/play-button@2x.png";
import LiveTvDisableIcon from "../../assets/icons/svg/LiveTvDiable";
import LiveTvActiveIcon from "../../assets/icons/svg/LiveTvActive";

const IconRender = (props) => {
  const ref = useRef(null);
  const { selectedMenuId, path, setShowLoginMessage, selectedChannel } =
    useContext(SessionContext);
  const history = useHistory();
  const onClickMenu = () => {
    if (
      props.text === localizationStrings.specialForMe ||
      props.text === localizationStrings.series
    ) {
      if (localStorage.getItem("usertype") === "0") {
        // let lang = localStorage.getItem("language");
        // let deviceid = localStorage.getItem("deviceid");
        // let softLoginUrl = localStorage.getItem("loginurl");
        // let loginUrl = `${softLoginUrl}?lan=${
        //   lang ? lang : "tur"
        // }&deviceid=${deviceid}`;
        // window.location.replace(loginUrl);
        setShowLoginMessage(true);
        return;
      }
    }

    let url =
      props.link !== "livetv"
        ? `/dashboard/home/${props.link}/all`
        : `/dashboard/${props.link}/${
            selectedChannel ? selectedChannel?.id : ""
          }`;

    history.push(url);
  };

  const renderIcon = () => {
    if (props.link === "livetv") {
      return selectedMenuId === props.link ? (
        <LiveTvActiveIcon />
      ) : (
        <LiveTvDisableIcon />
      );
    }
  };

  return (
    <a
      style={{ textDecoration: "none" }}
      className={`menu-item ${selectedMenuId == props.link ? "is-active" : ""}`}
      onClick={() => onClickMenu()}
    >
      <div className="category centered" ref={ref}>
        <label>{props.link === "livetv" ? "" : props.text}</label>
        {renderIcon()}
      </div>
    </a>
  );
};

export default IconRender;
