import { apiEndPointConstant } from "../../statics/constants";
import { axiosInstance } from "../config/configApi";

const getPaymentPageUrl = async (id) => {
  return await axiosInstance.get("/pay/purchase?productId=" + id);
};

const confirmUserPayment = async (mpay) => {
  return await axiosInstance.get("/pay/check?mPay=" + mpay);
};

const confirmCampaign = async (promocode) => {
  return await axiosInstance.post(
    apiEndPointConstant.authClient +
      "/subscription/promo?promoCode=" +
      promocode
  );
};

export { getPaymentPageUrl, confirmUserPayment, confirmCampaign };
