import React from "react";

const BackButtonIcon = ({ width = "10vw", height = "10vw" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="katman_1"
      version="1.1"
      viewBox="0 0 200 200"
      width={width}
      height={height}
      style={{ userSelect: "none" }}
    >
      <defs>
        <linearGradient
          id="Adsız_degrade_5"
          data-name="Adsız degrade 5"
          x1="4.18"
          y1="79.23"
          x2="195.82"
          y2="105.8"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#ff0078" />
          <stop offset="1" stopColor="#8300e6" />
        </linearGradient>
      </defs>
      <rect
        x="2.66"
        y="59.21"
        width="194.68"
        height="66.6"
        rx="33.3"
        ry="33.3"
        fill="url(#Adsız_degrade_5)"
        style={{ cursor: "pointer" }}
      />
      <g cursor="pointer">
        <path
          fill="#fff"
          d="M40.16,68.73c9.56,0,18.17,5.68,21.93,14.47.17.41.18.85.01,1.26-.16.41-.48.73-.88.9-.21.09-.42.13-.65.13-.66,0-1.26-.39-1.52-1-3.24-7.58-10.66-12.47-18.9-12.47-11.33,0-20.55,9.22-20.55,20.55,0,11.33,9.22,20.56,20.55,20.56,8.24,0,15.66-4.9,18.9-12.48.26-.61.86-1,1.52-1,.22,0,.44.04.65.13.84.36,1.23,1.33.87,2.17-3.76,8.8-12.37,14.48-21.94,14.48-13.16,0-23.86-10.7-23.86-23.86,0-13.15,10.7-23.85,23.86-23.85Z"
        />
        <path
          fill="#fff"
          d="M28.99,91.8l16.33-10.68c.42-.28.99.03.99.53v5.89c0,.35.29.64.64.64h18.48c.35,0,.64.28.64.64v7.05c0,.35-.29.64-.64.64h-18.48c-.35,0-.64.29-.64.64v5.89c0,.51-.56.81-.99.53l-16.33-10.68c-.38-.25-.38-.81,0-1.07Z"
        />
      </g>
      <text
        cursor="pointer"
        fill="#fff"
        fontFamily="'Myriad Pro', sans-serif"
        fontSize="33.51px"
        transform="translate(92.42 103.97)"
      >
        <tspan x="0" y="0">
          Geri
        </tspan>
      </text>
    </svg>
  );
};

export default BackButtonIcon;
