import React, { useContext, useEffect, useState } from "react";
import { SessionContext } from "../../context/SessionContext";
import { useHistory } from "react-router-dom";
import Logo from "../../assets/images/multi-screen.png";
import localizationStrings from "../../statics/localization";
import { getPaymentPageUrl } from "../../api/payment/payment";
import { doUnsub } from "../../api/user/user";
import Notification from "../notification";
import familyPackageLogo from "../../assets/images/familyPackage.png";
import sportPackageLogo from "../../assets/images/sportPackage.png";
import premiumPackageLogo from "../../assets/images/premiumPackage.png";

const PackageContent = ({ key, item, currentUserPackage, count, select }) => {
  const { packageId, setPackageId } = useContext(SessionContext);
  const [showPurchaseConfirmWarning, setShowPurchaseConfirmWarning] =
    useState(false);
  const [showPurchaseWarning, setShowPurchaseWarning] = useState(false);
  const setProduct = async (item) => {
    setPackageId(item);
  };

  console.log("Current User Package", currentUserPackage);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Ay değerini 0 ile doldurup formatlamak
    const day = date.getDate().toString().padStart(2, "0"); // Gün değerini 0 ile doldurup formatlamak
    return `${day}-${month}-${year}`;
  };

  const onClickConfirm = async () => {
    setProduct(item);
    await doUnsub(currentUserPackage[0].guid);
    setShowPurchaseConfirmWarning(false);
    setShowPurchaseWarning(true);
  };

  const history = useHistory();
  return (
    <div className="package-item">
      {item.name === "Premium" ? (
        <div class="ribbon">{localizationStrings.popular}</div>
      ) : null}
      <img
        className="package-icon"
        src={
          item.name === "Aile"
            ? familyPackageLogo
            : item.name === "Premium"
            ? premiumPackageLogo
            : item.name === "Spor Extra"
            ? sportPackageLogo
            : Logo
        }
      />
      <div className="package-info">
        <div className="package-name">{item.name}</div>
        <div className="package-desc">
          {item.description?.split("\n").map((line, index) => (
            <p key={index}>{line}</p>
          ))}
        </div>
        <div className="package-price">{item.price}₺</div>
      </div>

      {currentUserPackage[0]?.productP.name === item.name && (
        <>
          <div className="package-button-selected">
            {localizationStrings.myCurrentPackage}
          </div>
          <div
            className="package-button-cancel"
            onClick={() => {
              setShowPurchaseConfirmWarning(true);
            }}
          >
            {localizationStrings.cancelCurrentPackage}
          </div>
        </>
      )}
      {currentUserPackage[0]?.productP.name === "a" ||
      currentUserPackage[0]?.productP.name === item.name ? null : (
        <>
          <div
            className="package-button-cancel"
            onClick={() => {
              setProduct(item);
              history.push("/purchase");
            }}
          >
            {localizationStrings.buyVod}
          </div>
        </>
      )}

      {/* <div
        className={
          currentUserPackage[0]?.productP.name === item.name
            ? "package-button-selected"
            : "package-button"
        }
        onClick={() => {
          setProduct(item);
          history.push("/purchase");
        }}
      >
        {currentUserPackage[0]?.productP.name === item.name
          ? localizationStrings.myCurrentPackage
          : item.name}
      </div> */}
      {/* {currentUserPackage[0]?.productP.name === item.name &&
      currentUserPackage[0].status !== "SUSPENDED" ? (
        <div
          className={"cancel-package-button"}
          onClick={() => {
            setShowPurchaseConfirmWarning(true);
          }}
        >
          {localizationStrings.cancelCurrentPackage}
        </div>
      ) : null} */}
      {currentUserPackage[0]?.productP.name === item.name &&
      currentUserPackage[0].status === "SUSPENDED" ? (
        <label className="package-info" style={{ fontSize: "10px" }}>
          Abonelik Bitiş Tarihi - {formatDate(currentUserPackage[0].endDate)}
        </label>
      ) : null}
      {showPurchaseConfirmWarning ? (
        <Notification
          text={localizationStrings.productSureNotification}
          confirmText={localizationStrings.continue}
          notConfirmText={localizationStrings.cancel}
          onClickConfirm={onClickConfirm}
          onClickNotConfirm={() => {
            setShowPurchaseConfirmWarning(false);
          }}
        />
      ) : null}
      {showPurchaseWarning ? (
        <Notification
          text={localizationStrings.productOkNotification}
          confirmText={localizationStrings.continue}
          onClickConfirm={() => {
            setShowPurchaseWarning(false);
            window.location.reload();
          }}
        />
      ) : null}
    </div>
  );
};
export default PackageContent;
