import React, { useEffect, useRef, useState, useCallback } from "react";
import Genre from "../genre";
import ChannelListItem from "../channelListItem";
import { useHistory } from "react-router-dom";
import Notification from "../notification";
import localizationStrings from "../../statics/localization";
import BigLockIcon from "../../assets/icons/svg/BigLockIcon";
import { toggleLockById } from "../../api/ltv/ltv";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import AngleRightIcon from "../../assets/icons/svg/AngleRightIcon";
import ArrowRight from "../../assets/icons/svg/ArrowRight";

const ChannelListWidget = ({
  uKey,
  channels,
  genres,
  selectedChannel,
  onSelectChannel,
  setSelectedCutvProgram,
  selectedGenre,
  setSelectedGenre,
  isFilterOpen,
  setIsFilterOpen,
  adminProfile,
  profile,
  userFrameConfig,
  setLockChannelCode,
  setCanWatch,
  isPlayer,
  showList
}) => {
  const [useFavoriteName, setUseFavoriteName] = useState(false);
  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [askAdminPin, setAskAdminPin] = useState(false);
  const [notificationText, setNotificationText] = useState(null);

  const history = useHistory();
  const itemRef = useRef(null);
  const isCanWatch = useRef(false);
  const slider = useRef(null);

  let slidesToShow = isFilterOpen ? 5 : 8;

  let slidesToScroll = isFilterOpen ? 3 : 5;

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: slidesToShow,
    slidesToScroll: slidesToScroll,
    arrows: false,
    draggable: false,
    lazyLoad: false,
    vertical: true, // Enable vertical mode
    verticalSwiping: true
  };

  const next = () => {
    slider.current.slickNext();
  };

  const onSelectGenre = (genre) => {
    setSelectedGenre(genre);
    setIsFilterOpen(!isFilterOpen);
    if (genre.columncode === "fav-channel") {
      setUseFavoriteName(true);
    } else {
      setUseFavoriteName(false);
    }
  };

  const checkLock = (item) => {
    let textObject = {};
    itemRef.current = item;

    let isLocked = item?.interactions?.find(
      (element) => element === "CHANNEL_LOCK"
    );

    if (profile?.hasPin) {
      setShowPasswordModal(true);
      textObject.confirmText = localizationStrings.enter;
      if (profile?.main) {
        if (!!isLocked) {
          textObject.text =
            localizationStrings.profile.enterAdminProfilePasswordToUnlock;
        } else {
          textObject.text =
            localizationStrings.profile.enterAdminProfilePasswordToLock;
        }
      } else {
        if (!!isLocked) {
          textObject.text =
            localizationStrings.profile.enterProfilePasswordToUnlock;
        } else {
          textObject.text =
            localizationStrings.profile.enterProfilePasswordToLock;
        }
      }
    } else {
      textObject.text = localizationStrings.createProfilePinCodeText;
      textObject.confirmText = localizationStrings.createProfilePinCode;
    }

    setNotificationText(textObject);
    setShowNotification(true);
  };

  const clickConfirm = () => {
    setShowNotification(false);
    if (!showPasswordModal) {
      history.push(`/profile/${profile?.id}?page=1`);
    }
  };

  const handleToggleLock = async (channelId) => {
    const { data } = await toggleLockById(channelId);
    let isLocked = data.find((element) => element === "CHANNEL_LOCK");
    setLockChannelCode(channelId, isLocked);
  };

  const renderedGenreItems = genres?.map((genre) => {
    return (
      <Genre
        key={`${uKey}-${genre}`}
        item={genre}
        selectedItem={selectedGenre}
        onSelect={onSelectGenre}
      />
    );
  });

  useEffect(() => {
    if (selectedChannel) {
      let isLocked = selectedChannel?.interactions?.find(
        (element) => element === "CHANNEL_LOCK"
      );

      let textObj = {};

      if (!!isLocked) {
        /* selectedChannel.canWatch = false; */
        setShowPasswordModal(true);
        textObj.text = localizationStrings.profile.enterProfilePassword;
        textObj.confirmText = localizationStrings.enter;

        setNotificationText(textObj);

        setShowNotification(true);

        /* isCanWatch.current = true; */
      }

      /*  if (selectedChannel.islocked === "1" && !selectedChannel?.canWatch) {
        let textObj = {};
        if (profile?.profileroleid === userFrameConfig.Kids_Profile_Id) {
          textObj.text = localizationStrings.profile.enterAdminProfilePassword;
          textObj.confirmText = localizationStrings.enter;
          setAskAdminPin(true);
        } else {
          if (profile.havepwd === "1") {
            setShowPasswordModal(true);
            textObj.text = localizationStrings.profile.enterProfilePassword;
            textObj.confirmText = localizationStrings.enter;
          } else {
            setShowPasswordModal(false);
            textObj.text = localizationStrings.createProfilePinCodeText;
            textObj.confirmText = localizationStrings.createProfilePinCode;
          }
        }

        isCanWatch.current = true;
        setNotificationText(textObj);

        setShowNotification(true);
      } */
    }
  }, [selectedChannel]);

  const renderedChannelItems = channels?.map((item, index) => {
    return (
      <div key={`tys-${item.id}`}>
        <div style={{ display: "flex" }}>
          <ChannelListItem
            key={`${uKey}-${item.channelcode}`}
            item={item}
            index={index}
            selectedChannel={selectedChannel}
            onSelectChannel={onSelectChannel}
            useFavoriteName={useFavoriteName}
            onClickLock={(el) => checkLock(el)}
            uKey={uKey}
            showList={showList}
          />
        </div>
      </div>
    );
  });

  return (
    <div style={{ width: "100%" }}>
      <div className="list">
        <div
          className={`genre-container ${
            isFilterOpen ? (isPlayer ? "open-player" : "open") : ""
          }`}
        >
          <div
            className="vertical-genre-list"
            style={
              isPlayer
                ? {
                    minHeight: "calc(100vh - 388px)",
                    maxHeight: "calc(100vh - 388px)"
                  }
                : null
            }
          >
            {renderedGenreItems}
          </div>
        </div>
        {!!renderedChannelItems?.length && (
          <div
            id={`vertical-channel-list-${uKey}`}
            className="vertical-channel-list"
            style={
              isPlayer
                ? {
                    maxHeight: "calc(100vh - 200px)",
                    width: isFilterOpen && !isPlayer ? "60%" : "100%"
                  }
                : null
            }
          >
            {renderedChannelItems}
          </div>
        )}
      </div>

      {showNotification ? (
        <Notification
          text={notificationText?.text}
          confirmText={notificationText?.confirmText}
          onClickConfirm={clickConfirm}
          onClickNotConfirm={() => {
            setShowNotification(false);
            isCanWatch.current = false;
          }}
          isPassword={showPasswordModal}
          icon={<BigLockIcon />}
          sendResult={(val) => {
            if (val) {
              if (isCanWatch.current) {
                setCanWatch(true);
              } else {
                handleToggleLock(itemRef.current?.id);
              }
              isCanWatch.current = false;
            }
          }}
          /* profileCode={
            askAdminPin ? adminProfile.profilecode : profile.profilecode
          } */
          dontUsePortal={isPlayer}
          showCloseButton={true}
        />
      ) : null}
    </div>
  );
};

export default ChannelListWidget;
