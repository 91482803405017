import React, { useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import videojs from "video.js";
import AudioOffIcon from "../../assets/icons/svg/AudioOffIcon";
import AudioOnIcon from "../../assets/icons/svg/AudioOnlIcon";

const playerOptions = {
  fill: true,
  fluid: true,
  responsive: true,
  preload: "auto",
  controls: false,
  liveui: false,
  autoplay: true,
  loop: true
};

const TrailerPlayer = ({ playUrl, poster, isAudioOn, isPlay }) => {
  const playerRef = useRef(null);
  // const playUrl = "https://filbox.atlastv.net/contents/100000866/package/master.m3u8";

  const initPlayer = () => {
    if (!playerRef.current)
      playerRef.current = videojs("trailer-player", playerOptions);
    playerRef.current.poster(poster);
    playerRef.current.src([
      {
        src: playUrl,
        // type: "video/mp4"
        type: "application/x-mpegURL"
        // type: "video/x-matroska"
      }
    ]);
    playerRef.current.play();
  };

  useEffect(() => {
    playUrl && initPlayer();
  }, [playUrl]);

  useEffect(() => {
    return () => {
      playerRef.current && playerRef.current.dispose();
    };
  }, []);

  useEffect(() => {
    playerRef.current.volume(isAudioOn ? 1 : 0);
  }, [isAudioOn]);

  useEffect(() => {
    if (playerRef.current) {
      if (!isPlay) {
        playerRef.current.pause();
      } else {
        playerRef.current.play();
      }
    }
  }, [isPlay]);

  return (
    <div
      onClick={(e) => {
        if (e.target.localName.includes("video")) {
          if (playerRef.current.paused()) {
            playerRef.current.play();
          } else {
            playerRef.current.pause();
          }
        }
      }}
    >
      <video
        id="trailer-player"
        className="video-js trailer-videojs-player"
      ></video>
    </div>
  );
};

export default TrailerPlayer;
