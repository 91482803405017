import moment from "moment";
import { getEncryptData } from "../api/config/configApi";
import { generateRandom } from "./generateRandom";

let fingerPrintEl = "";
let fingerPrintElId = "";
let intervalId = null;
let timeoutId = null;
let player_ = null;
let mesStyle = {};
let normalStyle = {};
let isVisible = false;
let fpTimeout;
let timeStamp;
let timeout;
let left;
let bottom;

export const initFingerPrint = ({
  player,
  bottom,
  left,
  bgColor,
  color,
  fontSize,
  timeStamp,
  interval,
  timeout,
  fontFamily,
  fontWeight,
  fromMes
}) => {
  // if (!!fingerPrintEl) {
  //   unHideFingerPrint();
  //   return;
  // }
  if (isVisible) {
    return;
  }
  player_ = player;
  //destroyFingerPrint(); //when init call again, reset everything

  if (!fingerPrintEl) {
    fingerPrintEl = document.createElement("div");
  }
  fingerPrintElId = Math.random() * 1000000;
  fingerPrintEl.setAttribute("id", `${fingerPrintElId}`);
  fingerPrintEl.style.position = "absolute";
  fingerPrintEl.style.zIndex = "100000";
  timeStamp = timeStamp;
  timeout = timeout;
  left = left;
  bottom = bottom;
  // fingerPrintEl.style.backgroundColor = bgColor;
  // fingerPrintEl.style.color = color;
  // fingerPrintEl.style.fontSize = fontSize;
  // fingerPrintEl.style.fontFamily = fontFamily;
  // fingerPrintEl.style.fontWeight = fontWeight;

  if (fromMes) {
    mesStyle = {
      backgroundColor: bgColor,
      color: color,
      fontSize: fontSize,
      fontFamily: fontFamily,
      fontWeight: fontWeight
    };
    setStyles(mesStyle);
    player.off("fullscreenchange");
    player.on("fullscreenchange", () => {
      setFontSize(mesStyle.fontSize);
    });
    clearTimeout(intervalId);
    intervalId = null;
    showFingerPrint(timeStamp, timeout, left, bottom, true);
  } else {
    fpTimeout = interval;
    normalStyle = {
      backgroundColor: bgColor,
      color: color,
      fontSize: fontSize,
      fontFamily: fontFamily,
      fontWeight: fontWeight
    };
    fingerPrintTimeout(timeStamp, timeout, left, bottom);
  }
};

const fingerPrintTimeout = (timeStamp, timeout, left, bottom) => {
  intervalId && clearTimeout(intervalId);
  intervalId = setTimeout(() => {
    if (!isVisible) {
      setStyles(normalStyle);
      player_.off("fullscreenchange");
      player_.on("fullscreenchange", () => {
        setFontSize(normalStyle.fontSize);
      });
      showFingerPrint(timeStamp, timeout, left, bottom);
      intervalId = null;
    }
  }, fpTimeout * 1000 * 60);
};

const setStyles = (style) => {
  setFontSize(style.fontSize);
  fingerPrintEl.style.backgroundColor = style.backgroundColor;
  fingerPrintEl.style.color = style.color;
  fingerPrintEl.style.fontFamily = style.fontFamily;
  fingerPrintEl.style.fontWeight = style.fontWeight;
};

const setFontSize = (fontSize) => {
  if (player_.isFullscreen()) {
    fingerPrintEl.style.fontSize = fontSize + "px";
  } else {
    fingerPrintEl.style.fontSize = fontSize * 0.6 + "px";
  }
};

const generateFingerPrintText = async (timeStamp, callBack) => {
  const { data } = await getEncryptData(timeStamp);
  if (data.returncode == "0") {
    callBack(data.encrydata);
  }
};

const showFingerPrint = (
  timeStamp,
  timeout,
  rangeX,
  rangeY,
  fromMes = false
) => {
  // let randomNum = parseInt(Math.random() * 10) % 2; //generate 0 or 1 to show right side or left side
  // let min = 0;
  // let max = 0;
  let verticalPosition = 0; //vertical random number
  let horizontalPosition = 0; //horizontal random number

  // if (randomNum) {
  //   min = 0;
  //   max = rangeX;
  // } else {
  //   min = 100 - rangeX;
  //   max = 100;
  // }
  verticalPosition = generateRandom(rangeY, 100 - rangeY); //position vertical
  horizontalPosition = generateRandom(rangeX, 100 - rangeX); //position vertical

  //position horizontal
  // if (randomNum2 > rangeY && randomNum2 < 100 - rangeY) {
  //   randomNum3 = generateRandom(min, max);
  // } else {
  //   randomNum3 = generateRandom();
  // }
  generateFingerPrintText(timeStamp, (val) => {
    if (val) {
      fingerPrintEl.innerText = val;
      player_.el().appendChild(fingerPrintEl);

      if (verticalPosition > 50) {
        fingerPrintEl.style.top = `${100 - verticalPosition - rangeY}%`;
      } else {
        fingerPrintEl.style.bottom = `${verticalPosition}%`;
      }

      if (horizontalPosition > 50) {
        fingerPrintEl.style.right = `${100 - horizontalPosition - rangeX}%`;
      } else {
        fingerPrintEl.style.left = `${horizontalPosition}%`;
      }
      isVisible = true;
      // console.log("SHOW_FINGER: ", moment().format("h:mm:ss"));

      timeoutId = setTimeout(() => {
        closeFingerPrint();
        !intervalId && fingerPrintTimeout(timeStamp, timeout, rangeX, rangeY);
      }, timeout * 1000);
    }
  });
};

const closeFingerPrint = () => {
  // console.log("CLOSE_FINGER: ", moment().format("h:mm:ss"));
  fingerPrintEl.style.top = "unset";
  fingerPrintEl.style.bottom = "unset";
  fingerPrintEl.style.left = "unset";
  fingerPrintEl.style.right = "unset";
  fingerPrintEl && fingerPrintEl.remove();
  isVisible = false;
};

export const destroyFingerPrint = (player) => {
  // console.log("DESTROY_FINGER", moment().format("h:mm:ss"), player_);
  player_ && player_.off("fullscreenchange");
  fingerPrintEl && fingerPrintEl.remove();
  fingerPrintEl = "";
  intervalId && clearTimeout(intervalId);
  timeoutId && clearTimeout(timeoutId);
  isVisible = false;
};

export const hideFingerPrint = () => {
  if (fingerPrintEl) fingerPrintEl.style.display = "none";
};

export const unHideFingerPrint = (player = null) => {
  fingerPrintEl && (fingerPrintEl.style.display = "block");
  if (player) {
    player.el().appendChild(fingerPrintEl);
  }
};
