import React, { useContext, useRef, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";

import ShowPasswordIcon from "../../assets/icons/svg/ShowPasswordIcon";
import HidePasswordIcon from "../../assets/icons/svg/HidePasswordIcon";
import localizationStrings from "../../statics/localization";
import { getLoginToken, registerUser } from "../../api/login/loginApi";
import { getProfilesApi, loginWithProfile } from "../../api/user/user";
import LoginWrapper from "../../components/loginWrapper";
import mainLoadingGif from "../../assets/loading.gif";
import "./SignUp.scss";
import ArrowRightBlack from "../../assets/icons/svg/ArrowRightBlack";
import ArrowRightPurple from "../../assets/icons/svg/ArrowRightPurple";

const SignUp = () => {
  const [isShownPassword, setIsShownPassword] = useState({
    password: false,
    confirmPassword: false
  });
  const [notSubMessage, setNotSubMessage] = useState(null);
  const [showError, setShowError] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const history = useHistory();

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors }
  } = useForm();

  const { signUp, password, rememberMe, forgotPassword, changePassword } =
    localizationStrings.login;

  const { emptyMessageText } = localizationStrings;

  const handleRegister = async (userData) => {
    setShowLoading(true);
    const { data } = await registerUser(
      userData.username,
      userData.name,
      userData.surname,
      userData.email,
      userData.password
    );
    if (data.code == 200) {
      handleLogin(userData);
    } else if (data.code == 500) {
      setShowError(true);
    }
    setShowLoading(false);
  };

  const handleLogin = async (loginData) => {
    const { data } = await getLoginToken(
      loginData.username,
      loginData.password
    );

    if (!!data && data?.token) {
      localStorage.setItem("userToken", data.token);
      localStorage.setItem("hasLoggedIn", true);
      localStorage.setItem("logintype", "paid");
      getProfiles();
    }
  };

  const getProfiles = async () => {
    const { data } = await getProfilesApi();
    localStorage.setItem("profile", JSON.stringify(data[0]));

    if (!!data) {
      loginProfile(data[0]);
    }
  };

  const loginProfile = async (item) => {
    const { data } = await loginWithProfile(item.id, item.pin);

    if (!!data && data?.token) {
      localStorage.setItem("profileToken", data.token);
      history.push("/account/package");
    }
  };

  const handleError = (errors) => {};

  const handleTogglePassword = (field) => {
    setIsShownPassword((prevState) => ({
      ...prevState,
      [field]: !prevState[field]
    }));
  };

  const registerOptions = {
    username: {
      required: `${emptyMessageText}`,
      minLength: {
        value: 3,
        message: "Kullanıcı adı giriniz."
      }
    },
    name: {
      required: `${emptyMessageText}`,
      minLength: {
        value: 3,
        message: "İsim giriniz."
      }
    },
    surname: {
      required: `${emptyMessageText}`,
      minLength: {
        value: 3,
        message: "Soy isim giriniz."
      }
    },

    email: {
      required: `${emptyMessageText}`,
      validate: (value) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return (
          emailRegex.test(value) || "Bu e-posta adresi eksik veya hatalıdır."
        );
      }
    },
    password: {
      required: `${emptyMessageText}`,
      minLength: {
        value: 8,
        message: "En az 8 karakter giriniz."
      }
    },
    confirmPassword: {
      required: `${emptyMessageText}`,
      minLength: {
        value: 8,
        message: "En az 8 karakter giriniz."
      },
      validate: (val) => {
        if (watch("password") != val) {
          return "Şifreler eşleşmiyor";
        }
      }
    }
  };
  return (
    <LoginWrapper>
      <p className="login-title" style={{ color: "white" }}>
        Filbox
      </p>
      <p
        className="login-title"
        style={{ color: "white", fontSize: "30px", marginTop: "5px" }}
      >
        Üyelik Girişi
      </p>

      <label className="signup-label">Üyelik Bilgilerinizi Girin</label>
      <input
        className="input-number"
        name="username"
        type="username"
        placeholder={`Kullanıcı Adı`}
        {...register("username", registerOptions.username)}
        style={
          errors.username || notSubMessage
            ? { border: "2px solid red", marginTop: "25px" }
            : { marginTop: "25px" }
        }
      />
      <div className="input-error">
        <p> {errors?.username && errors.username.message}</p>
      </div>

      <input
        className="input-number"
        name="name"
        type="name"
        placeholder={`İsim`}
        {...register("name", registerOptions.name)}
        style={
          errors.name || notSubMessage
            ? { border: "2px solid red", marginTop: "25px" }
            : { marginTop: "25px" }
        }
      />
      <div className="input-error">
        <p> {errors?.name && errors.name.message}</p>
      </div>

      <input
        className="input-number"
        name="surname"
        type="surname"
        placeholder={`Soy İsim`}
        {...register("surname", registerOptions.surname)}
        style={
          errors.surname || notSubMessage
            ? { border: "2px solid red", marginTop: "25px" }
            : { marginTop: "25px" }
        }
      />
      <div className="input-error">
        <p> {errors?.surname && errors.surname.message}</p>
      </div>

      <input
        className="input-number"
        name="email"
        type="email"
        placeholder={`E-posta adresi`}
        {...register("email", registerOptions.email)}
        style={
          errors.email || notSubMessage
            ? { border: "2px solid red", marginTop: "25px" }
            : { marginTop: "25px" }
        }
      />
      <div className="input-error">
        <p> {errors?.email && errors.email.message}</p>
      </div>

      <div className="password-section">
        <input
          className="input-password"
          name="password"
          type={isShownPassword.password ? "text" : "password"}
          placeholder={`${password}`}
          {...register("password", registerOptions.password)}
          style={
            errors.password || notSubMessage ? { border: "2px solid red" } : {}
          }
        />
        <div className="input-error">
          <p> {errors?.password && errors.password.message}</p>
        </div>
        <span
          className="eye-icon"
          onClick={() => handleTogglePassword("password")}
        >
          {isShownPassword.password ? (
            <ShowPasswordIcon />
          ) : (
            <HidePasswordIcon />
          )}
        </span>
      </div>

      <div className="password-section">
        <input
          className="input-password"
          name="confirmPassword"
          type={isShownPassword.confirmPassword ? "text" : "password"}
          placeholder={`Şifre tekrar`}
          {...register("confirmPassword", registerOptions.confirmPassword)}
          style={
            errors.confirmPassword || notSubMessage
              ? { border: "2px solid red" }
              : {}
          }
        />
        <div className="input-error">
          <p> {errors?.confirmPassword && errors.confirmPassword.message}</p>
        </div>
        <span
          className="eye-icon"
          onClick={() => handleTogglePassword("confirmPassword")}
        >
          {isShownPassword.confirmPassword ? (
            <ShowPasswordIcon />
          ) : (
            <HidePasswordIcon />
          )}
        </span>
      </div>
      {showError ? (
        <div className="input-error">
          <p>Bu kullanıcı adıyla zaten bir hesap bulunmaktadır.</p>
        </div>
      ) : null}
      <div className="login-button-container">
        <button
          onClick={() => {
            history.push("/login");
          }}
          className="back-btn"
        >
          <ArrowRightPurple /> Geri
        </button>
        <button
          onClick={handleSubmit(handleRegister, handleError)}
          style={{ cursor: showLoading ? "not-allowed" : "" }}
          disabled={showLoading}
          className="continue-btn"
        >
          {showLoading ? (
            <div className="loading-gif">
              <img src={mainLoadingGif} />
            </div>
          ) : (
            <>
              Devam <ArrowRightBlack />
            </>
          )}
        </button>
      </div>
    </LoginWrapper>
  );
};

export default SignUp;
