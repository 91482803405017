import React from "react";

const InfoButtonIcon = ({ width = "8vw", height = "5vw" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 500 300"
      aria-labelledby="infoIconTitle"
      role="img"
      width={width}
      height={height}
    >
      <title id="infoIconTitle">Info Icon</title>
      <defs>
        <linearGradient
          id="gradient1"
          x1="162.94"
          y1="150"
          x2="337.06"
          y2="150"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#ec0868" />
          <stop offset="1" stopColor="#8300e6" />
        </linearGradient>
        <style>
          {`
                    .circle {
                        fill: url(#gradient1);
                    }
                    .outline {
                        fill: none;
                        stroke: #fff;
                        stroke-miterlimit: 10;
                        stroke-width: 3px;
                    }
                    .rect {
                        fill: #fff;
                    }
                    `}
        </style>
      </defs>
      <circle className="circle" cx="250" cy="150" r="87.06" />
      <path
        className="outline"
        d="M250,221.71c-39.6,0-71.71-32.11-71.71-71.71s32.11-71.71,71.71-71.71,71.71,32.11,71.71,71.71"
      />
      <rect
        className="rect"
        x="242.29"
        y="111.83"
        width="15.43"
        height="16.07"
        rx="7.71"
        ry="7.71"
      />
      <rect
        className="rect"
        x="242.29"
        y="133.17"
        width="15.42"
        height="55"
        rx="7.71"
        ry="7.71"
      />
    </svg>
  );
};

export default InfoButtonIcon;
