import React from "react";

const SignInNowIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    id="katman_1"
    viewBox="0 0 500 300"
    style={{ maxWidth: "15vw", height: "15vw" }}
  >
    <defs>
      <style>
        {`
          .cls-3 { fill: url(#Adsiz_degrade_12); }
          .cls-4 { fill: url(#Adsiz_degrade_12-2); }
          .cls-5 { fill: url(#Adsiz_degrade_14); }
          .cls-2 {
            font-family: ArialMT, Arial;
            font-size: 32px;
          }
        `}
      </style>
      <linearGradient
        id="Adsiz_degrade_12"
        x1="67.57"
        y1="150"
        x2="445.61"
        y2="150"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#ec0868" />
        <stop offset="1" stopColor="#8300e6" />
      </linearGradient>
      <linearGradient
        id="Adsiz_degrade_12-2"
        x1="115.77"
        y1="135.13"
        x2="148.64"
        y2="181.42"
        xlinkHref="#Adsiz_degrade_12"
      />
      <linearGradient
        id="Adsiz_degrade_14"
        x1="129.27"
        y1="125.55"
        x2="162.14"
        y2="171.84"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#ec0868" />
        <stop offset="0.16" stopColor="#db077d" />
        <stop offset="1" stopColor="#8300e6" />
      </linearGradient>
    </defs>
    <path
      fill="#fff"
      className="cls-3"
      d="M400.99,105.72h-208.01c-12.91-16.01-32.7-26.24-54.87-26.24-38.96,0-70.53,31.57-70.53,70.53s31.57,70.52,70.53,70.52c21.85,0,41.39-9.94,54.32-25.56h208.57c12.33,0,23.48-5,31.56-13.07,8.07-8.08,13.07-19.24,13.07-31.56,0-24.64-19.98-44.62-44.62-44.62ZM184.7,174.93c-8.91,16.61-26.44,27.91-46.6,27.91-29.18,0-52.83-23.66-52.83-52.83s23.64-52.83,52.83-52.83c20.16,0,37.68,11.29,46.6,27.91,3.97,7.43,6.23,15.91,6.23,24.92s-2.26,17.49-6.23,24.92Z"
    />
    <path
      fill="#fff"
      d="M190.93,150c0,9.01-2.26,17.49-6.23,24.92-8.91,16.61-26.44,27.91-46.6,27.91-29.18,0-52.83-23.66-52.83-52.83s23.64-52.83,52.83-52.83c20.16,0,37.68,11.29,46.6,27.91,3.97,7.43,6.23,15.91,6.23,24.92Z"
    />
    <text className="cls-2" transform="translate(203.47 159.56)">
      <tspan fill="#fff" x="0" y="0">
        Hemen Üye Ol
      </tspan>
    </text>
    <path
      className="cls-4"
      d="M156.78,177.81h-37.34c-3.11-.14-6.96-1.45-8.35-4.46-.87-1.88-1.47-5.22-1.22-7.27.05-.43.16-.91.25-1.34,1.35-6.54,4.81-13.21,12.31-13.59.69-.03,1.08-.05,1.69.26,1.93.99,3.63,2.49,5.65,3.44,5.6,2.64,11.09,2.63,16.69-.01,1.8-.85,3.37-2.1,5.07-3.11.3-.18.87-.55,1.21-.58.72-.07,1.69.05,2.41.17,6.53,1.11,9.49,7.01,10.82,12.9.13.59.3,1.26.37,1.85.26,2.15-.43,5.89-1.5,7.79-1.48,2.62-5.24,3.82-8.07,3.95Z"
    />
    <path
      className="cls-5"
      d="M137.38,122.21c10.59-.46,18.47,9.21,16.08,19.53-1.41,6.1-6.91,11.05-13.12,11.87-10.32,1.37-18.93-6.95-17.97-17.29.72-7.73,7.29-13.78,15.01-14.11Z"
    />
  </svg>
);

export default SignInNowIcon;
