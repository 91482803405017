import React from "react";

const WatchNowIcon = ({ maxWidth = "15vw", height = "15vw" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 400 150"
      style={{ maxWidth: "100%", height: "auto", maxHeight: "100%" }}
    >
      <defs>
        <linearGradient
          id="gradient1"
          x1="54.42"
          y1="150"
          x2="432.45"
          y2="150"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#ec0868" />
          <stop offset="1" stopColor="#8300e6" />
        </linearGradient>
        <linearGradient
          id="gradient2"
          x1="103.37"
          y1="150"
          x2="148.49"
          y2="150"
          xlinkHref="#gradient1"
        />
        <style>
          {`
            .text {
              fill: #fff;
              font-family: Arial, sans-serif;
              font-size: 45px;
              stroke: #fff;
              stroke-miterlimit: 10;
              stroke-width: 0.5px;
            }
          `}
        </style>
      </defs>
      {/* Arka Plan ve Daire */}
      <path
        cursor={"pointer"}
        fill="url(#gradient1)"
        d="M387.83,105.72h-208.01c-12.91-16.01-32.7-26.24-54.87-26.24-38.96,0-70.53,31.57-70.53,70.53s31.57,70.52,70.53,70.52c21.85,0,41.39-9.94,54.32-25.56h208.57c12.33,0,23.48-5,31.56-13.07,8.07-8.08,13.07-19.24,13.07-31.56,0-24.64-19.98-44.62-44.62-44.62ZM171.54,174.93c-8.91,16.61-26.44,27.91-46.6,27.91-29.18,0-52.83-23.66-52.83-52.83s23.64-52.83,52.83-52.83c20.16,0,37.68,11.29,46.6,27.91,3.97,7.43,6.23,15.91,6.23,24.92s-2.26,17.49-6.23,24.92Z"
        transform="translate(-40, -75)"
      />
      <path
        fill="#fff"
        d="M177.78,150c0,9.01-2.26,17.49-6.23,24.92-8.91,16.61-26.44,27.91-46.6,27.91-29.18,0-52.83-23.66-52.83-52.83s23.64-52.83,52.83-52.83c20.16,0,37.68,11.29,46.6,27.91,3.97,7.43,6.23,15.91,6.23,24.92Z"
        transform="translate(-40, -75)"
      />
      {/* Metin */}
      <text
        className="text"
        x="260.38"
        y="164.68"
        transform="translate(-40, -75)"
      >
        İzle
      </text>
      {/* Oynatma Butonu */}
      <path
        fill="url(#gradient2)"
        d="M145.96,145.61l-34.99-20.2c-3.38-1.95-7.6.49-7.6,4.39v40.4c0,3.9,4.22,6.34,7.6,4.39l34.99-20.2c3.38-1.95,3.38-6.83,0-8.78Z"
        transform="translate(-40, -75)"
      />
    </svg>
  );
};

export default WatchNowIcon;
