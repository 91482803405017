import React, { useContext, useEffect, useState } from "react";
import AngleRightPurple from "../../assets/icons/svg/AngleRightPurple";
const DownloadItem = ({ key, item, count }) => {
  const [dropdownCheck, setDropdownCheck] = useState(false);
  useEffect(() => {
    if (item.type == 2) {
      setDropdownCheck(true);
    }
  }, []);
  return (
    <div className="download-item">
      <div className="download-name">{item.name}</div>
      {dropdownCheck ? (
        <div className="download-extender">
          <span>
            <AngleRightPurple />
          </span>
        </div>
      ) : null}
    </div>
  );
};
export default DownloadItem;
