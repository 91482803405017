import React, { useContext, useEffect, useRef, useState } from "react";
import {
  getProfilesApi,
  loginWithProfile,
  checkProfilePassword,
  callLogout,
  checkProfilePin
} from "../../api/user/user";
import ProfileItem from "../profileItem/ProfileItem";
import localizationStrings from "../../statics/localization";
import { SessionContext } from "../../context/SessionContext";
import { useHistory, useParams } from "react-router-dom";
import Notification from "../notification/Notification";
import { setCookieApi } from "../../api/config/configApi";
import { sendAliveReport } from "../../utils/reporting/customReporting";
import {
  defaultProfileAvatar,
  getColectAgent,
  getWs,
  setWhereFrom
} from "../../statics/config";
import mainLogo from "../../assets/logo/filbox-main-logo.png";
import settingsIcon from "../../assets/images/settings-icon.png";
import downloadIcon from "../../assets/images/download-icon.png";
import playbuttonIcon from "../../assets/images/play-button@2x.png";
import logoutIcon from "../../assets/images/logout-icon.png";
import backIcon from "../../assets/images/filbox-back-icon.png";
import PopupCloseIcon from "../../assets/icons/svg/PopupCloseIcon";
import { reportingEvent } from "../../statics/reportEventId";
import moment from "moment";
import { getMainboards } from "../../api/vod/vod";
import { isFirefox } from "react-device-detect";
import { firefoxPinSecret } from "../../utils/firefoxPinSecret";
import openKillSessionPopup from "../../utils/openKillSessionPopup";
import AngleRightPurple from "../../assets/icons/svg/AngleRightPurple";
import AngleLeftPurple from "../../assets/icons/svg/AngleLeftPurple";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ArrowRightPurple from "../../assets/icons/svg/ArrowRightPurple";
import BackButtonIcon from "../../assets/icons/svg/BackButton";

const ProfileList = ({ isFromProfileLogin }) => {
  const [showPinCode, setShowPinCode] = useState(false);
  const [showEditPinCode, setShowEditPinCode] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [selectedProfile, setSelectedProfile] = useState(null);
  const [pinCode, setPinCode] = useState("");
  const [avatar, setAvatar] = useState("");
  const [showNotification, setShowNotification] = useState(false);
  const [notificationText, setNotificationText] = useState("");
  const [showLoading, setShowLoading] = useState(false);
  const [showProfileDetail, setShowProfileDetail] = useState(false);
  const [closeClicked, setCloseClicked] = useState(0);
  const [backgroundImageUrl, setBackgroundImageUrl] = useState(false);
  const [pinText, setPinText] = useState("");
  const [i, setI] = useState(0);
  const [showCloseIcon, setShowCloseIcon] = useState(false);
  const [accountIcon, setaccountIcon] = useState("");
  const [showAlert, setShowAlert] = useState(false);

  const {
    hasLoggedIn,
    setHasLoggedIn,
    profile,
    setProfile,
    adminProfile,
    userFrameConfig,
    userInfo,
    setSelectedChannel,
    setAllProfiles,
    allProfiles,
    handleLogout
  } = useContext(SessionContext);
  let history = useHistory();
  const timeoutRef = useRef(null);
  const addButton = {
    id: -1,
    name: localizationStrings.profile.addProfile
  };
  let { id } = useParams(); //profilecode if click on navbar
  const isAvatarOpened = useRef(false);
  const [isSettingsClicked, setIsSettingsClicked] = useState(false);
  const sliderRef = useRef();
  const [currentSlide, setCurrentSlide] = useState(0);

  let currentProfile = JSON.parse(localStorage.getItem("profile"));
  const profileQuota = currentProfile?.deviceQuota;

  useEffect(() => {
    let free = localStorage.getItem("logintype");
    if (free === "free") {
      history.push("/login");
    }
    //!hasLoggedIn && history.replace("/select-login");
    let logged = localStorage.getItem("hasLoggedIn");
    !logged && history.replace("/login");
  }, []);

  useEffect(() => {
    if (isFromProfileLogin) {
      if (isSettingsClicked) {
        document.querySelector(".icon-log").classList.add("clicked");
      } else {
        document.querySelector(".icon-log").classList.remove("clicked");
      }
    }
  }, [isSettingsClicked]);

  const getBackgroundImage = async () => {
    const { data } = await getMainboards(6);
    if (data.returncode === "0") {
      let obj = data.data[0];
      let poster = obj?.posterpath + obj?.phonebanner;
      setBackgroundImageUrl(poster);
      localStorage.setItem("backgroundImageUrl", poster);
    }
  };

  const getProfiles = async () => {
    const { data } = await getProfilesApi();

    if (!!data) {
      setAllProfiles(data);
    }
  };

  const onProfileSelection = (item) => {
    localStorage.setItem("firstLogin", JSON.stringify(true));
    setSelectedProfile(item);
    item.posterimage = `${process.env.PUBLIC_URL}/profilePosters/${item.posterName}.png`;
    setAvatar(item.posterimage);
    setaccountIcon(item.posterName);
    if (!item?.hasPin) {
      setShowLoading(true);
      setShowCloseIcon(true);
      setShowPinCode(true);
      timeoutRef.current = setTimeout(() => {
        loginProfile(item);
      }, 3000);
    } else {
      setShowPinCode(true);
      setShowCloseIcon(true);
    }
  };

  const goSettings = (item) => {
    setSelectedProfile(item);
    item.posterimage = `${process.env.PUBLIC_URL}/profilePosters/${item.posterName}.png`;
    setAvatar(item.posterimage);
    if (!item?.hasPin || (item?.kid && currentProfile?.main)) {
      history.push(`/profile/${item.id}`);
    } else {
      setShowEditPinCode(true);
    }
  };

  const loginProfile = async (item) => {
    //setShowLoading(true);
    //setShowPinCode(true);
    setShowCloseIcon(false);
    const { data } = await loginWithProfile(item.id, item.pin);

    if (!!data && data?.token) {
      setSelectedChannel(null);
      localStorage.setItem("profileToken", data.token);
      localStorage.setItem("profile", JSON.stringify(item));
      setProfile(item);
      history.push("/dashboard/home/6/all");
    }
  };

  const checkPinCode = async () => {
    if (!pinCode) return setShowAlert(true);
    setShowLoading(true);
    setShowCloseIcon(true);

    const { data } = await checkProfilePin(selectedProfile?.id, pinCode);

    if (data) {
      if (showEditPinCode) {
        history.push(`/profile/${selectedProfile?.id}`);
      } else {
        if (!isFromProfileLogin) {
          timeoutRef.current = setTimeout(() => {
            loginProfile(selectedProfile);
            if (!!document.pictureInPictureElement) {
              setWhereFrom("");
              document.exitPictureInPicture();
            }
          }, 3000);
        } else {
          loginProfile(selectedProfile);
        }
      }
    } else {
      setShowLoading(false);
      setShowCloseIcon(false);
      if (!data) {
        setShowNotification(true);
        setNotificationText(localizationStrings.wrongPinText);
      }
    }
  };

  const logout = async () => {
    const { data } = await callLogout(userInfo?.usercode);
    if (data.ReturnCode === "0") {
      let collectAgent = getColectAgent();
      if (collectAgent) {
        let eventtime = moment(
          moment().valueOf() - parseInt(localStorage.getItem("timeDiff"))
        )
          .utc()
          .format("yyyyMMDDHHmmss");

        collectAgent.logLogout({
          eventid: reportingEvent.E_LOGIN_OUT,
          eventtime: eventtime, //login triggering time (String UTC time, eg: "20210326101813"
          actiontime: eventtime,
          result: "0",
          logintype: 0 //login type 0: Account login, 1: Mobile login, 2: Mailbox login, 3: Loginname login (String, eg: "3."
        });
      }
      sendAliveReport();
      let ws = getWs();
      if (ws?.readyState === WebSocket.OPEN) {
        let prams = {
          TransactionID: JSON.stringify(new Date().getTime()),
          DeviceID: localStorage.getItem("deviceid"),
          UserID: userInfo?.usercode,
          TerminalFlag: 4,
          UserToken: userInfo?.usertoken,
          DKey: 0,
          Timestamp: JSON.stringify(new Date().getTime()),
          MsgType: "terminaldelreq"
        };
        ws.send(JSON.stringify(prams));
      }

      if (parseInt(localStorage.getItem("logintype")) == 4) {
        localStorage.clear();
        openKillSessionPopup(userFrameConfig?.easHttpsDomain);
        //window.location.replace(fastLoginSessionKillUrl);
      } else {
        localStorage.clear();
        window.location.replace(userFrameConfig?.easHttpsDomain);
      }
    }
  };

  const removeCookieApi = async (key, value) => {
    const { data } = await setCookieApi(key, value);
    if (data.returncode === "0") {
      if (key === "upd") {
        removeCookieApi("uid", "");
      } else if (key === "uid") {
        logout();
      }
    }
  };
  const PrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div className={className} onClick={onClick}>
        <span>
          <AngleLeftPurple />
        </span>
      </div>
    );
  };
  const NextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div className={className} onClick={onClick}>
        <span>
          <AngleRightPurple />
        </span>
      </div>
    );
  };

  const handleClick = async () => {
    await handleLogout();

    setTimeout(() => {
      history.replace("/dashboard/home/6/all");
    }, 1000);
  };

  const renderedItems = allProfiles.map((item) => {
    return (
      <ProfileItem
        key={item.id}
        item={item}
        onSelection={onProfileSelection}
        currentProfile={currentProfile}
        isFromProfileLogin={isFromProfileLogin}
        count={allProfiles.length + 1}
        onEditClick={goSettings}
      />
    );
  });
  var slidesShow = renderedItems.length < 3 ? renderedItems.length : 3;
  var settings = {
    speed: 500,
    slidesToShow: slidesShow,
    slidesToScroll: 1,
    infinite: false,
    arrows: true,
    draggable: false,
    lazyLoad: false,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />
  };

  useEffect(() => {
    // getBackgroundImage();
    getProfiles();
  }, []);

  return (
    <section
      className="profile-container"
      style={{ background: `url(${backgroundImageUrl}) center center` }}
    >
      {(!isFromProfileLogin && !showLoading) ||
      (isFromProfileLogin && showPinCode) ? (
        <div className="row">
          <div className="logo-wrapper">
            <img
              src={mainLogo}
              alt="tivibu logo"
              style={{ maxWidth: "200px", height: "100px" }}
            />
          </div>
          {isFromProfileLogin === false ? (
            <div
              className="close"
              onClick={() => {
                if (isFromProfileLogin) {
                  setShowPinCode(false);
                } else if (showProfileDetail) {
                  if (isAvatarOpened.current) {
                    let count = closeClicked;
                    setCloseClicked(++count);
                  } else {
                    setShowProfileDetail(false);
                  }
                } else {
                  //history.push("/dashboard");
                  history.goBack();
                }
              }}
            >
              <a className="logout">Geri Dön</a>
            </div>
          ) : null}
        </div>
      ) : null}
      {showPinCode ? (
        <div className="pin-code-wrapper">
          <div className={`text-logging ${showLoading ? "show" : ""}`}>
            {localizationStrings.profile.logging}
          </div>
          <div className="profile-item" style={{ marginBottom: "5px" }}>
            <div className="profile-avatar">
              <img
                src={avatar}
                onError={() => setAvatar(defaultProfileAvatar)}
              />
              {showLoading ? <div className="profile-loading"></div> : null}
            </div>
            <div className="profile-name">{selectedProfile.name}</div>
          </div>

          {!showLoading ? (
            <div style={{ position: "relative", width: "100%" }}>
              <div style={{ position: "relative", width: "100%" }}>
                <form
                  id="profileListForm"
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                      checkPinCode();
                    }
                  }}
                >
                  {!isFirefox ? (
                    <input
                      style={{ width: "100%" }}
                      className={showPassword ? "" : "profile-input pin"}
                      autoComplete="off"
                      autoFocus={true}
                      name="profilePin"
                      id="profileListPin2"
                      type="number"
                      placeholder={localizationStrings.login.password}
                      onKeyDown={(e) =>
                        ["e", "E", "+", "-", ".", ","].includes(e.key) &&
                        e.preventDefault()
                      }
                      onChange={(e) => {
                        if (e.target.value.length > 4) {
                          e.target.value = e.target.value.slice(0, 4);
                        }
                        setPinCode(e.target.value);
                      }}
                    />
                  ) : (
                    <input
                      style={{ width: "100%" }}
                      className={
                        showPassword ? "profile-input" : "profile-input"
                      }
                      autoComplete="off"
                      onMouseUp={(e) => {
                        let [pin, i] = firefoxPinSecret(null, e);
                        setI(i);
                        setPinText(pin.replace(/[0-9,•]/g, "•"));
                        setPinCode(pin);
                      }}
                      onKeyDown={(e) => {
                        let [pin, i] = firefoxPinSecret(e, null);
                        setI(i);
                        setPinText(pin.replace(/[0-9,•]/g, "•"));
                        setPinCode(pin);
                      }}
                      autoFocus={true}
                      name="profilePin"
                      id="profileListPin2"
                      type="text"
                      placeholder={localizationStrings.login.password}
                      onChange={(e) => {
                        e.target.value = pinText;
                        e.target.selectionEnd = i;
                        e.target.selectionStart = i;
                      }}
                    />
                  )}
                  {showAlert && !pinCode ? (
                    <div className="alert-text">
                      {localizationStrings.profile.emptyPin}
                    </div>
                  ) : null}
                  <div
                    className="yellow-button"
                    style={{ marginTop: "2.6vw" }}
                    onClick={() => {
                      checkPinCode();
                    }}
                  >
                    {localizationStrings.signIn}
                  </div>
                </form>
              </div>
            </div>
          ) : !showCloseIcon ? (
            <div
              // className="close"
              onClick={() => {
                timeoutRef.current && clearTimeout(timeoutRef.current);
                setShowLoading(false);
                setShowPinCode(false);
                setShowCloseIcon(false);
              }}
            >
              {/* <PopupCloseIcon /> */}
            </div>
          ) : null}
        </div>
      ) : showEditPinCode ? (
        <div className="pin-code-wrapper">
          <div style={{ position: "relative", width: "100%" }}>
            <div className="text">
              {localizationStrings.enterProfilePassword}
            </div>
            <form
              id="profileListForm"
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                  checkPinCode();
                }
              }}
            >
              {!isFirefox ? (
                <input
                  style={{ width: "100%" }}
                  className="profile-input pin"
                  autoComplete="off"
                  autoFocus={true}
                  name="profilePin"
                  id="profileListPin"
                  type="number"
                  placeholder={localizationStrings.login.password}
                  onKeyDown={(e) =>
                    ["e", "E", "+", "-", ".", ","].includes(e.key) &&
                    e.preventDefault()
                  }
                  onChange={(e) => {
                    if (e.target.value.length > 4) {
                      e.target.value = e.target.value.slice(0, 4);
                    }
                    setPinCode(e.target.value);
                  }}
                />
              ) : (
                <input
                  style={{ width: "100%" }}
                  className="profile-input"
                  autoComplete="off"
                  autoFocus={true}
                  name="profilePin"
                  id="profileListPin"
                  type="text"
                  onMouseUp={(e) => {
                    let [pin, i] = firefoxPinSecret(null, e);
                    setI(i);
                    setPinText(pin.replace(/[0-9,•]/g, "•"));
                    setPinCode(pin);
                  }}
                  onKeyDown={(e) => {
                    let [pin, i] = firefoxPinSecret(e, null);
                    setI(i);
                    setPinText(pin.replace(/[0-9,•]/g, "•"));
                    setPinCode(pin);
                  }}
                  placeholder={localizationStrings.login.password}
                  onChange={(e) => {
                    e.target.value = pinText;
                    e.target.selectionEnd = i;
                    e.target.selectionStart = i;
                  }}
                />
              )}
              {showAlert && !pinCode ? (
                <div className="alert-text">
                  {localizationStrings.profile.emptyPin}
                </div>
              ) : null}
              <div
                className="yellow-button"
                style={{ marginTop: "2.6vw" }}
                onClick={() => checkPinCode()}
              >
                {localizationStrings.continue}
              </div>
            </form>
          </div>
        </div>
      ) : (
        <div className="profile-list">
          <div className="profile-selection-wrapper">
            {isFromProfileLogin && (
              <div style={{ height: "100%" }}>
                <div
                  style={{
                    width: "8vw",
                    height: "3vw",
                    marginTop: "2.5vh",
                    marginLeft: "3vw"
                  }}
                  onClick={() => {
                    history.push("dashboard/home/6/all");
                  }}
                >
                  <BackButtonIcon />
                </div>
              </div>
            )}
            {/* {isFromProfileLogin && (
              <div style={{ height: "85%" }}>
                <div
                  className="back-btn button-back"
                  style={{ width: "8vw", height: "3vw" }}
                  onClick={() => {
                    history.push("dashboard/home/6/all");
                  }}
                >
                  <ArrowRightPurple /> Geri
                </div>
              </div>
            )} */}
            <div className="profile-selection">
              <div className="profile-wrapper">
                <div className="text">
                  {isFromProfileLogin
                    ? localizationStrings.profileManagement
                    : null}
                </div>
                {/* <div className="text">
              {isFromProfileLogin
                ? localizationStrings.profileLoginText
                : localizationStrings.profileManagement}
              </div> */}
                <div className="profile-items-box">
                  {/*{renderedItems}*/}
                  <Slider
                    {...settings}
                    style={{
                      width: "40vw",
                      height: "60%",
                      marginBottom: "100px",
                      maxWidth: "90%"
                    }}
                    ref={sliderRef}
                    key={Date.now()}
                  >
                    {renderedItems.map((item) => item)}
                  </Slider>
                  {/* {!isFromProfileLogin && currentProfile?.main ? (
                <ProfileItem
                  item={addButton}
                  onSelection={() => {
                    history.push("/profile/new");
                  }}
                  count={allProfiles?.length + 1}
                />
              ) : null} */}
                </div>
                {/*{isFromProfileLogin ? (
              <div
                className="red-button"
                onClick={() => {
                  handleLogout();
                  //history.replace("/select-login");
                  history.replace("/login");
                }}
              >
                {localizationStrings.logout2}
              </div>
            ) : null}*/}
                <div className="profile-items-box">
                  {currentProfile?.main && allProfiles.length < profileQuota ? (
                    <ProfileItem
                      item={addButton}
                      onSelection={() => {
                        history.push("/profile/new");
                      }}
                      count={allProfiles?.length + 1}
                    />
                  ) : null}
                </div>
                <div className="text-login">
                  {!isFromProfileLogin
                    ? localizationStrings.profileLoginText
                    : null}
                </div>
              </div>
              {isFromProfileLogin ? (
                <>
                  <div className="profile-settings-line"></div>
                  <div className="profile-settings">
                    <div className="text">{localizationStrings.myProfile} </div>
                    <div className="profile-settings-wrapper">
                      <div className="profile-div">
                        <div
                          className="icon"
                          onClick={() => {
                            history.push(`/account/settings`);
                          }}
                        >
                          <img
                            className="icon-img"
                            src={settingsIcon}
                            alt="settings icon"
                          />
                          <div className="icon-text">
                            {localizationStrings.accountIcon}
                          </div>
                        </div>
                        <div
                          className="icon"
                          onClick={() => {
                            history.push(`/account/package`);
                          }}
                        >
                          <img
                            className="icon-img"
                            src={playbuttonIcon}
                            alt="download icon"
                          />
                          <div className="icon-text">
                            {localizationStrings.packageIcon}
                          </div>
                        </div>
                      </div>
                      <div className="icon-log" onClick={handleClick}>
                        <img
                          className="icon-img"
                          src={logoutIcon}
                          alt="logout icon"
                        />
                        <div className="icon-text">
                          {localizationStrings.closeSession}
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : null}
            </div>
          </div>
        </div>
      )}
      {showNotification ? (
        <Notification
          text={notificationText}
          confirmText={localizationStrings.close}
          onClickConfirm={() => setShowNotification(false)}
        />
      ) : null}
      <div className="profile-gradient"></div>
      {isFromProfileLogin ? <div className="profile-gradient-2"></div> : null}
    </section>
  );
};

export default ProfileList;
