import React, { useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { getMainboards } from "../../api/vod/vod";
import MainboardItem from "./mainboardItem";
import MainboardThumbnail from "../mainboardThumbnail/MainboardThumbnail";

const Mainboard = ({ bannerPosition, mainBoardVods }) => {
  const slider = useRef(null);
  const timeoutRef = useRef(null);
  let autoplaySpeed = 10000;

  // const getMainbordVods = async (bannerPosition) => {
  //   const { data } = await getMainboards(bannerPosition);
  //   if (data.returncode === "0") {
  //     // for sorting mainboard data
  //     data.data.sort((a, b) => {
  //       return a?.sortnum - b?.sortnum;
  //     });
  //     setResults(data.data);
  //   } else {
  //     setResults([]);
  //   }
  // };

  // useEffect(() => {
  //   if (bannerPosition) {
  //     setResults([]);
  //     getMainbordVods(bannerPosition);
  //   }
  // }, [bannerPosition]);

  useEffect(() => {
    if (mainBoardVods?.length) {
      if (slider.current) {
        timeoutRef.current && clearTimeout(timeoutRef.current);
        timeoutRef.current = setTimeout(() => {
          slider.current.slickGoTo(0);
          slider.current.slickPlay();
        }, 300);
      }
    }
  }, [mainBoardVods]);

  useEffect(() => {
    return () => {
      timeoutRef.current && clearTimeout(timeoutRef.current);
    };
  }, []);

  const renderedResults = mainBoardVods?.map((vod) => {
    return <MainboardItem key={vod?.id} item={vod} />;
  });

  if (mainBoardVods?.length) {
    var settings = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      autoplay: true,
      autoplaySpeed: autoplaySpeed,
      draggable: false,
      pauseOnHover: false,
      afterChange: (index) => {
        if (mainBoardVods?.length == index + 1) {
          setTimeout(() => {
            slider.current.slickGoTo(0);
          }, autoplaySpeed);
        }
      }
    };
  }

  return (
    <div className="banner-wrapper">
      <Slider ref={slider} {...settings}>
        {renderedResults}
      </Slider>
      <div className="mainboard-thumbnail-wrapper">
        <MainboardThumbnail
          // key={bannerPosition}
          items={mainBoardVods}
          selectIndex={(index) => slider.current.slickGoTo(index)}
        />
      </div>
    </div>
  );
};

export default Mainboard;
