import React from "react";

const ArrowIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26.219"
      height="48.196"
      viewBox="0 0 26.219 48.196"
    >
      <path
        id="Path_340"
        data-name="Path 340"
        d="M298.477,512.09,275.44,535.127l23.037,23.037"
        transform="translate(-273.319 -511.029)"
        fill="none"
        stroke="#e675f8"
        stroke-miterlimit="10"
        stroke-width="3"
      />
    </svg>
  );
};

export default ArrowIcon;
