import React, { useContext, useEffect, useRef } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { Route, useHistory, Redirect } from "react-router-dom";
import ErrorFallBack from "./components/errorFallBack";

import Routing from "./components/routing";
import ProtectedRoute from "./secure/protectedRoute/ProtectedRoute";
import "./style/Main.scss";

import "moment/locale/tr";
import Navbar from "./components/navbar";
import Footer from "./components/footer";

import LoginPage from "./components/loginPage";
import SignUp from "./views/signUp";
import WireCard from "./components/wireCard";
import Purchase from "./views/purchase/Purchase";
import { axiosInstance } from "./api/config/configApi";
import axios from "axios";
import ErrorPage from "./views/errorPage/ErrorPage";
import ForgotPasswordPage from "./components/forgotPasswordPage/ForgotPasswordPage";
import ResetPassword from "./components/resetPassword/ResetPassword";
import Packets from "./components/packets/Packets";
import ProfileSelection from "./views/profileSelection/ProfileSelection";
import SelectLoginPage from "./components/selectLoginPage/SelectLoginPage";
import ProfileSettings from "./components/profileSettings";
import ProfileList from "./components/profileList/ProfileList";
import Package from "./components/package/Package";
import VersionInfo from "./components/versionInfo/VersionInfo";
import DownloadList from "./components/downloadList/DownloadList";
import { getLoginToken } from "./api/login/loginApi";
import { getProfilesApi, loginWithProfile } from "./api/user/user";
import { SessionContext } from "./context/SessionContext";
import { loginEvent } from "./api/event/event";

function App() {
  const history = useHistory();

  const { setHasLoggedIn, userFrameConfig } = useContext(SessionContext);

  const loginGuessProfile = async () => {
    localStorage.removeItem("userToken");
    localStorage.removeItem("profileToken");
    localStorage.removeItem("hasLoggedIn");
    const handleLogin = async () => {
      const loginCredentials = {
        password: "Misafirkullanici",
        userName: "Misafirkullanici"
      };
      const { data } = await getLoginToken(
        loginCredentials.userName,
        loginCredentials.password
      );
      if (!!data && data?.token) {
        localStorage.setItem("userToken", data.token);
        localStorage.setItem("hasLoggedIn", true);
        setHasLoggedIn(true);
        getGuessProfiles();
      }
    };
    handleLogin();
  };

  const getGuessProfiles = async () => {
    const { data } = await getProfilesApi();

    if (!!data) {
      getLoginGuessProfile(data[0]);
    }
  };

  const getLoginGuessProfile = async (item) => {
    //setShowLoading(true);
    //setShowPinCode(true);
    const { data } = await loginWithProfile(item.id, item.pin);

    if (!!data && data?.token) {
      localStorage.setItem("profileToken", data.token);
      localStorage.setItem("profile", JSON.stringify(item));
      localStorage.setItem("logintype", "free");
      // history.push("/dashboard/livetv/all");
      window.location.reload();
      const loginEventControl = async () => {
        const { event } = await loginEvent();
      };
      loginEventControl();
    }
  };

  axiosInstance.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      if (error.response.status === 403 || error.response.status === 401) {
        loginGuessProfile();
      }

      return Promise.reject(error);
    }
  );

  return (
    <div>
      <div>
        <div>
          <Route path="/" exact>
            <Redirect to="/dashboard/home/6/all" />
          </Route>
          <Route path="/login" component={LoginPage} />
          {/* <Route exact path="/login">
            <Redirect to="/dashboard" />
          </Route> */}
          <Route path="/signup" component={SignUp} />
          <Route path="/packets" component={Package} />
          <Route path="/purchase" component={Purchase} />
          <Route path="/errorpage" component={ErrorPage} />
          <Route path="/forgot-pw" component={ForgotPasswordPage} />
          <Route path="/forgot-password" component={ResetPassword} />
          {/* <Route path="/redirect" component={LoginRedirect} />
            <Route path="/landingpage" component={LandingPage} />
            <Route path="/select-login" component={SelectLoginPage} />
            <Route path="/forgot-password" component={ForgotPasswordPage} />
            <Route path={`/channelcontrol`} component={ChannelControl} />
            <Route path={`/channelcoverage`} component={ChannelCoverage} />
            <Route path={`/channellocking`} component={ChannelLocking} />
            <ProtectedRoute path="/profiles/:id">
              <ProfileList />
            </ProtectedRoute>
            <ProtectedRoute path="/profile/:profileCode">
              <ProfileSettings />
            </ProtectedRoute>
            <Route path="/selectprofile" component={ProfileSelection} /> */}
          <Route path="/selectprofile" component={ProfileSelection} />
          <Route path="/select-login" component={SelectLoginPage} />
          <ProtectedRoute path="/profiles/:id">
            <ProfileList />
          </ProtectedRoute>
          <ProtectedRoute path="/profile/:profileCode">
            <ProfileSettings />
          </ProtectedRoute>
          <ProtectedRoute path="/account/package">
            <Package />
            <Footer />
          </ProtectedRoute>
          <ProtectedRoute path="/account/settings">
            <VersionInfo />
          </ProtectedRoute>
          <ProtectedRoute path="/account/download">
            <DownloadList />
          </ProtectedRoute>
          <Route path="/dashboard">
            <Routing />
          </Route>
        </div>
      </div>
    </div>
  );
}

export default App;
