import React from "react";

const XFooterIcon = ({ width = "54px", height = "54px" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="54"
      height="54"
      viewBox="0 0 54 54"
      aria-labelledby="xFooterIconTitle"
      role="img"
    >
      {/* <title id="xFooterIconTitle">X Footer Icon</title>
      <defs>
        <style>
          {`
                    .cls-1 {
                        fill: none;
                        stroke: #8960c5;
                        stroke-miterlimit: 10;
                        stroke-width: 6px;
                    }
                    .cls-2 {
                        fill: #8960c5;
                    }
                    `}
        </style>
      </defs>
      {/* Circle updated for r=100 */}
      {/* <circle className="cls-1" cx="100" cy="100" r="100" /> */}
      <g transform="translate(1 1)">
        {/* Path coordinates scaled for 200x200 */}
        <path
          id="base"
          d="M26,0A26,26,0,1,1,0,26,26,26,0,0,1,26,0Z"
          transform="translate(0 0)"
          fill="none"
          stroke="#b783ff"
          strokeWidth="2"
          opacity="0.5"
        />
        <path
          className="cls-2"
          d="M70.66,69.9l24.83,33.2-24.98,26.96h5.63l21.87-23.63,17.69,23.63h19.14l-26.23-35.05,23.27-25.11h-5.63l-20.14,21.74-16.27-21.74h-19.14ZM77.6,74.04h8.79l38.79,51.87h-8.79l-38.79-51.87Z"
          transform="translate(-11.7 -11.5) scale(0.37)"
          fill="#8960c5"
        />
      </g>
    </svg>
  );
};

export default XFooterIcon;
