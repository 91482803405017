import React, { useContext, useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";

import ShowPasswordIcon from "../../assets/icons/svg/ShowPasswordIcon";
import HidePasswordIcon from "../../assets/icons/svg/HidePasswordIcon";
import localizationStrings from "../../statics/localization";
import "./LoginPage.scss";
import LoginWrapper from "../loginWrapper";
import { getLoginToken, getSubscription } from "../../api/login/loginApi";
import { getProfilesApi, loginWithProfile } from "../../api/user/user";
import mainLoadingGif from "../../assets/loading.gif";
import { SessionContext } from "../../context/SessionContext";
import { loginEvent } from "../../api/event/event";
import Notification from "../notification";
import DocumentButton from "../../assets/icons/svg/DocumentButton";

const LoginPage = () => {
  const [isShownPassword, setIsShownPassword] = useState(false);
  const [notSubMessage, setNotSubMessage] = useState(null);
  const [showLimitWarning, setShowLimitWarning] = useState(false);
  const [showButtonLoading, setShowButtonLoading] = useState(false);
  const { setHasLoggedIn, handleLogout, setUserPackage } =
    useContext(SessionContext);
  const history = useHistory();
  localStorage.setItem("firstLogin", JSON.stringify(false));

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm();

  const {
    notYetSub,
    filboxPassword,
    mobilePhoneNumber,
    password,
    rememberMe,
    forgotPassword,
    changePassword
  } = localizationStrings.login;

  const { emptyMessageText } = localizationStrings;

  const handleLogin = async (loginData) => {
    localStorage.setItem("logintype", "paid");
    try {
      setShowButtonLoading((prevState) => !prevState);
      const { data } = await getLoginToken(
        loginData.userName,
        loginData.password
      );
      if (!!data && data?.token) {
        localStorage.setItem("userToken", data.token);
        localStorage.setItem("hasLoggedIn", true);
        setHasLoggedIn(true);
        getProfiles();
        getMySubscribe();
      }
      setShowButtonLoading((prevState) => !prevState);
    } catch (error) {
      setShowLimitWarning(true);
    }
  };

  const handleOpenPDF = () => {
    window.open(`${window.location.origin}/nasil-uye-olunur.pdf`, "_blank");
  };

  const getProfiles = async () => {
    const { data } = await getProfilesApi();

    if (!!data) {
      history.push("/selectprofile");
      //loginProfile(data[0]);
    }
  };

  const loginProfile = async (item) => {
    //setShowLoading(true);
    //setShowPinCode(true);
    const { data } = await loginWithProfile(item.id, item.pin);

    if (!!data && data?.token) {
      localStorage.setItem("profileToken", data.token);
      history.push("/dashboard/livetv");
      //history.push("/selectprofile");
      const loginEventControl = async () => {
        const { event } = await loginEvent();
      };
      loginEventControl();
    }
    const loginEventControl = async () => {
      const { event } = await loginEvent();
    };
    loginEventControl();
  };

  const getMySubscribe = async () => {
    const { data } = await getSubscription();
    if (data?.length) {
      localStorage.setItem("userPackage", JSON.stringify(data[0]));
    }
  };

  const handleError = (errors) => {};

  const handleClickChange = () => {
    window.open(
      "https://teksifre.turktelekom.com.tr/kullanici-giris/sso-giris?bmctx=96703605727DD648953DAFF3520DB8DEE37B490F44F558382E1B33734CAAEC72&password=secure_string&contextType=external&username=string&challenge_url=https%3A%2F%2Fteksifre.turktelekom.com.tr%2Fkullanici-giris%2Fsso-giris&request_id=-7201795736024367508&authn_try_count=0&locale=tr_TR&resource_url=https%253A%252F%252Fteksifre.turktelekom.com.tr%252Fkullanici-islemleri%252Fsifre-degistirme-giris"
    );
  };

  const handleTogglePassword = () => {
    setIsShownPassword((prevState) => !prevState);
  };

  const registerOptions = {
    userName: {
      required: `${emptyMessageText}`,
      minLength: {
        value: 1,
        message: "Password must have at least 8 characters"
      }
    },
    password: {
      required: `${emptyMessageText}`,
      minLength: {
        value: 1,
        message: "Password must have at least 8 characters"
      }
    }
  };

  useEffect(() => {
    // localStorage.removeItem("userToken");
    // localStorage.removeItem("profileToken");
    // localStorage.removeItem("hasLoggedIn");
    // localStorage.setItem("logintype", "null");
    handleLogout();
    // loginGuessProfile();
  }, []);

  return (
    <LoginWrapper>
      <p className="login-title" style={{ color: "white", fontSize: "40px" }}>
        Filbox
      </p>
      <p
        className="login-title"
        style={{ color: "white", fontSize: "25px", marginTop: "5px" }}
      >
        Şifre ile Giriş
      </p>
      <input
        className="input-number"
        name="userName"
        placeholder={`${mobilePhoneNumber}`}
        {...register("userName", registerOptions.userName)}
        style={
          errors.userName || notSubMessage ? { border: "2px solid red" } : {}
        }
      />
      <div className="input-error">
        <p> {errors?.userName && errors.userName.message}</p>
      </div>

      <div className="password-section">
        <input
          className="input-password"
          name="password"
          type={isShownPassword ? "text" : "password"}
          placeholder={`${password}`}
          {...register("password", registerOptions.password)}
          style={
            errors.password || notSubMessage ? { border: "2px solid red" } : {}
          }
        />

        <span className="eye-icon" onClick={handleTogglePassword}>
          {isShownPassword ? <ShowPasswordIcon /> : <HidePasswordIcon />}
        </span>
      </div>
      <Link className="forgot-password" to="/forgot-pw">
        {forgotPassword}
      </Link>
      <button
        onClick={handleSubmit(handleLogin, handleError)}
        className="continue-button"
        style={{ cursor: showButtonLoading ? "not-allowed" : "" }}
        disabled={showButtonLoading}
      >
        {showButtonLoading ? (
          <div className="loading-gif">
            <img src={mainLoadingGif} />
          </div>
        ) : (
          localizationStrings.continue
        )}
      </button>
      <div className="login-line" />
      <p className="article-2">{notYetSub}</p>
      <button
        onClick={() => {
          history.push("/signup");
        }}
        style={{
          color: "#1B1629",
          backgroundColor: "#F07A22",
          marginTop: "1%"
        }}
        className="continue-button"
      >
        {localizationStrings.login.signUp}
      </button>
      <button
        onClick={() => handleOpenPDF()}
        style={{
          color: "#b783ff",
          marginTop: "1%",
          backgroundColor: "transparent",
          display: "flex",
          alignItems: "center",
          gap: 10
        }}
      >
        <DocumentButton />
        {localizationStrings.login.howBecomeMember}
      </button>
      {/* <button
        onClick={() => {
          loginGuessProfile();
        }}
        style={{
          color: "#1B1629",
          backgroundColor: "#F07A22",
          marginTop: "1%"
        }}
        className="continue-button"
      >
        {localizationStrings.login.guessUp}
      </button> */}
      {showLimitWarning ? (
        <Notification
          text={localizationStrings.loginErrorText}
          confirmText={localizationStrings.continue}
          onClickConfirm={() => {
            setShowButtonLoading((prevState) => !prevState);
            setShowLimitWarning(false);
          }}
        />
      ) : null}
    </LoginWrapper>
  );
};

export default LoginPage;
