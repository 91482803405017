import React, { useRef, useState } from "react";
import FacebookIcon from "../../assets/icons/svg/FacebookIcon";
import InstagramIcon from "../../assets/icons/svg/InstagramIcon";
import LinkedinIcon from "../../assets/icons/svg/LinkedinIcon";
import ShareIcon from "../../assets/icons/svg/ShareIcon";
import TwitterIcon from "../../assets/icons/svg/TwitterIcon";
import YayIcon from "../../assets/icons/svg/YayIcon";
import localizationStrings from "../../statics/localization";
import VisibilitySensor from "react-visibility-sensor";
import CopyIcon from "../../assets/icons/svg/CopyIcon";
import {
  WhatsappShareButton,
  WhatsappIcon,
  FacebookShareButton,
  TwitterShareButton,
  EmailShareButton,
  EmailIcon
} from "react-share";
import TwitterXIcon from "../../assets/icons/svg/TwitterXIcon";

const ShareAction = ({ vod, isPlayer }) => {
  const [showShareOptions, setShowShareOptions] = useState(false);
  const [isRight, setIsRight] = useState(false);
  const [message, setMessage] = useState("");
  const [shareUrl, setShareUrl] = useState("");
  const isVisibleRef = useRef(false);

  console.log("Share Vod", vod);

  const shareClicked = () => {
    setMessage(
      localizationStrings.formatString(localizationStrings.shareText, {
        contentName: vod?.title
      })
    );
    const shareUrl = window.location.href;
    setShareUrl(shareUrl);
  };

  const copyToClipboard = async (shareUrl) => {
    try {
      await navigator.clipboard.writeText(shareUrl);
    } catch (error) {
      console.error("Kopyalama Başarısız:", error);
    }
  };

  return (
    <div
      className={`action-button secondary ${
        showShareOptions ? "selected" : ""
      }`}
      onClick={() => {
        shareClicked();
        setShowShareOptions(!showShareOptions);
        if (showShareOptions) {
          setTimeout(() => {
            setIsRight(!isVisibleRef.current);
          }, 300);
        } else {
          setIsRight(!isVisibleRef.current);
        }
      }}
    >
      <span className="icon">
        <ShareIcon />
      </span>
      <VisibilitySensor>
        {({ isVisible }) => {
          isVisibleRef.current = isVisible;
          return (
            <div
              className={`custom-tooltip ${
                isRight ? "tooltip-right" : "tooltip-left"
              } ${showShareOptions ? "open" : ""}  ${
                isPlayer ? "player-tooltip" : ""
              }`}
            >
              {!isPlayer ? (
                <div className="tooltip-text">{localizationStrings.share}</div>
              ) : null}

              <div className="tooltip-wrapper">
                <div className="tooltip-button">
                  <WhatsappShareButton
                    url={shareUrl}
                    title={message}
                    placeholder="Whatsapp"
                    separator={`\n`}
                    windowHeight={750}
                    windowWidth={650}
                  >
                    <span className="tooltip-icon">
                      <WhatsappIcon
                        size={"100%"}
                        round={true}
                        //bgStyle={{ fill: "white" }}
                        className="share-icon"
                      />
                    </span>
                  </WhatsappShareButton>
                </div>

                <div className="tooltip-button">
                  <FacebookShareButton
                    url={shareUrl}
                    title="Facebook"
                    quote={message}
                    separator={`\n`}
                    windowHeight={550}
                    windowWidth={650}
                  >
                    <span className="tooltip-icon">
                      <FacebookIcon size={"100%"} />
                    </span>
                  </FacebookShareButton>
                </div>
                <div className="tooltip-button">
                  <TwitterShareButton
                    url={shareUrl}
                    title={message}
                    separator={`\n`}
                    windowHeight={450}
                    windowWidth={650}
                  >
                    <span className="tooltip-icon">
                      <TwitterXIcon />
                    </span>
                  </TwitterShareButton>
                </div>
                <div className="tooltip-button">
                  <EmailShareButton
                    url={shareUrl}
                    body={message}
                    separator={`\n`}
                  >
                    <span className="tooltip-icon">
                      <EmailIcon
                        bgStyle={{ fill: "#fff", color: "#000" }}
                        className="share-icon"
                        size={"100%"}
                        round
                      />
                    </span>
                  </EmailShareButton>
                </div>
                <div
                  onClick={copyToClipboard(shareUrl)}
                  className="tooltip-button"
                >
                  <span className="tooltip-icon">
                    <CopyIcon color="#000" />
                  </span>
                </div>
              </div>
            </div>
          );
        }}
      </VisibilitySensor>
    </div>
  );
};

export default ShareAction;
