import React from "react";
import { useHistory } from "react-router-dom";

import PlayerExitIcon from "../../assets/icons/svg/PlayerExitIcon";
import logo from "../../assets/logo/filbox-main-logo.png";
import LoginBackground from "../../assets/images/loginBackground";
import localizationStrings from "../../statics/localization";
import "./LoginWrapper.scss";

const LoginWrapper = ({ children, showLogo = true }) => {
  const { goBack } = useHistory();

  const { notYetSub, signUp } = localizationStrings.login;

  return (
    <section className="login-page-container">
      <div className="login-content">
        {showLogo && (
          <img className="filbox-logo" src={logo} alt="filbox-logo" />
        )}
        {children}
      </div>
    </section>
  );
};

export default LoginWrapper;
