import React, { useEffect, useRef, useState, useContext } from "react";
import {
  confirmUserPayment,
  getPaymentPageUrl
} from "../../api/payment/payment";
import useQuery from "../../utils/useQuery";
import successLogo from "../../assets/images/success.png";
import errorLogo from "../../assets/images/error.png";
import { useHistory } from "react-router-dom";
import mainLoadingGif from "../../assets/loading.gif";
import WireCard from "../../components/wireCard";
import {
  getAllPackages,
  getSubscription,
  logout
} from "../../api/login/loginApi";
import logo from "../../assets/logo/filbox-main-logo.png";
import LoginWrapper from "../../components/loginWrapper";
import ArrowRightPurple from "../../assets/icons/svg/ArrowRightPurple";
import footballImg from "../../assets/images/football.png";
import { SessionContext } from "../../context/SessionContext";
import { loginWithProfile } from "../../api/user/user";
import BackButtonIcon from "../../assets/icons/svg/BackButton";
import { getMainMenu } from "../../statics/config";

const Purchase = (item) => {
  const [isSuccess, setIsSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [iframeUrl, setIframeUrl] = useState(null);
  const [showButtonLoading, setShowButtonLoading] = useState(false);
  const { packageId, setPackageId } = useContext(SessionContext);
  const history = useHistory();
  const mpayRef = useRef(null);
  const profile = JSON.parse(localStorage.getItem("profile"));

  const setProfileToken = async () => {
    const { data } = await loginWithProfile(profile.id, profile.pin);
    const { subs } = await getSubscription();
    if (data?.length) {
      localStorage.setItem("userPackage", subs[0]);
    }
    localStorage.setItem("profileToken", data.token);
    const firstMenuId = getMainMenu()[0]?.id;
    firstMenuId
      ? history.push(`/dashboard/home/${firstMenuId}/all`)
      : history.push("/dashboard/home/6/all");
  };

  useEffect(() => {
    if (packageId?.id) {
      getPaymentPage(packageId.id);
    }
    window.scrollTo(0, 0);
  }, []);

  const confirmPayment = async (mpay) => {
    setIsLoading(true);
    const { data } = await confirmUserPayment(mpay);
    if (data == 0) {
      setIsLoading(false);
      setIsSuccess(true);
    } else {
      setIsLoading(false);
      setIsSuccess(false);
    }
  };

  const getPaymentPage = async (id) => {
    setShowButtonLoading(true);
    const { data } = await getPaymentPageUrl(id);
    if (data.redirectUrl) {
      setIframeUrl(data.redirectUrl);
      setIsLoading(false);
      mpayRef.current = data.mpay;
      setShowButtonLoading(false);
    }
  };
  /* 
  const getPackages = async () => {
    setShowButtonLoading(true);
    const { data } = await getAllPackages();
    if (data?.length && data[0]) {
      getPaymentPage(data[0].id);
    }
  }; */

  /*   const callLogout = async () => {
    const { data } = await logout();
    localStorage.setItem("hasLoggedIn", false);
    localStorage.removeItem("userToken");
    localStorage.removeItem("profileToken");
    history.push("/login");
  }; */
  return (
    <LoginWrapper showLogo={!isLoading}>
      {isLoading ? (
        <div
          className="loading-gif"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <img
            src={mainLoadingGif}
            style={{ width: "200px", height: "200px" }}
          />
        </div>
      ) : iframeUrl ? (
        <WireCard
          url={iframeUrl}
          closeFrame={() => {
            setIsLoading(false);
            setIframeUrl(null);
          }}
          confirmFrame={() => {
            setIsLoading(true);
            setIframeUrl(null);
            confirmPayment(mpayRef.current);
          }}
        />
      ) : isSuccess ? (
        <div className="account-wrapper">
          <div className="account-success-icon">
            <img
              src={successLogo}
              alt="success logo"
              style={{ maxWidth: "125px", height: "auto" }}
            />
          </div>
          <div className="account-text">Tebrikler!</div>
          <div className="account-feedback">
            <div className="text-section">
              <p
                className="account-text"
                style={{ fontSize: "20px", color: "#E675F8" }}
              >
                {packageId.name}
              </p>
              <p className="account-text" style={{ fontSize: "20px" }}>
                Paket satın alma işleminiz başarı ile gerçekleşmiştir. Filbox'ın
                ayrıcalıklı dünyasına hoş geldiniz...
              </p>
            </div>
            <img src={footballImg} alt="screen image" />
          </div>

          <button
            onClick={() => {
              setProfileToken();
            }}
            className="continue-button"
          >
            İzlemeye Başla
          </button>
        </div>
      ) : (
        <div className="account-wrapper">
          <div className="account-success-icon">
            <img
              src={errorLogo}
              alt="error logo"
              style={{ maxWidth: "125px", height: "auto" }}
            />
          </div>
          <div className="account-text">Hata!</div>
          <div className="account-feedback">
            <div className="text-section">
              <p
                className="account-text"
                style={{ fontSize: "20px", color: "#E675F8" }}
              >
                {packageId.name}
              </p>
              <p className="account-text" style={{ fontSize: "20px" }}>
                Paket satın alma işleminiz başarısız olmuştur. Lütfen
                bilgilerinizi kontrol edip tekrar deneyiniz!
              </p>
            </div>
            <img src={footballImg} alt="screen image" />
          </div>

          <button
            onClick={() => {
              setIsLoading(true);
              getPaymentPage(packageId.id);
            }}
            className="continue-button btn-again"
          >
            Tekrar Dene
          </button>
          <div
            onClick={() => {
              history.push("/account/package");
            }}
            style={{
              width: "8vw",
              height: "3vw",
              position: "absolute",
              top: "2.5vh",
              left: "3vw"
            }}
          >
            <div>
              <BackButtonIcon />
            </div>
          </div>
        </div>
      )}
    </LoginWrapper>
  );
};

export default Purchase;
